<template>
    <v-sheet>
      <Dialog :dialog="recordPaymentDialog" card-text-class="px-4 py-0" :dialogWidth="1200">
        <template v-slot:title><v-spacer>Emi Details</v-spacer></template>
        <template v-slot:body>
          <v-container>
            <v-row>
            </v-row>
            <template>
              <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
                <v-row>
                  <v-col md="12" mb="0">
                    <table width="100%" class="detail-table mb-10">
            <tr>
              <th width="200">Start Date</th>
              <td>{{loandata.date}}</td>
              <th width="200">Loan Amount</th>
              <td class="text-lowercase">
                {{loandata.loan_amount}}
              </td>
            </tr>
            <tr>
              <th width="200">Instalment</th>
              <td>
                {{loandata.total_emi}}
              </td>
              <th width="200">Status</th>
              <td>
                <v-chip  color="red" outlined v-if="discount_total ==0"> Open</v-chip>
                <v-chip  color="red" outlined v-if="discount_total !=0 && discount_total <loandata?.loan_amount "> Partial</v-chip>
                <v-chip  color="green" outlined v-if="discount_total !=0 && discount_total == loandata?.loan_amount "> Paid</v-chip>

              </td>
            </tr>
           
          
         
          </table>
                  </v-col>
                    <v-col md="12" mb="0">
                
                          <table width="100%">
                            <tr>
                             
                              <td style="border-left: 1px solid #000">
                                <table
                                  width="60%"
                                  class="grid auto-data-table table table-bordered table-sortable"
                                >
                                  <thead>
                                    
                                    <tr >
                                      <th  style="font-weight: bold; font-size: 18px">MONTH</th>
                                      <th style="font-weight: bold; font-size: 18px">EMI</th>
                                      <th style="font-weight: bold; font-size: 18px">PAID</th>

                                      
                                    </tr>
                                  </thead>
                                 
                                  <tbody v-click-outside="clickOutside">
                                    <template v-if="loandata?.loan_rel.length" 
  
  >
                                    <tr  v-for="(emi, rowindex) in loandata.loan_rel"
                    :key="rowindex">
                                      <td style="vertical-align: middle">
                                        <label class="btx-label">{{emi.month_name}}</label>
                                      </td>
                                      <td style="vertical-align: middle">
                                        <label
                                          style="font-weight: bold; font-size: 18px"
                                          class="btx-label custom-header-blue-text"
                                          >{{formatMoneys(emi.emi_amount)}}</label
                                        >

                                      </td>
                                      <td style="vertical-align: middle">
                                        <label
                                          style="font-weight: bold; font-size: 18px"
                                          class="btx-label custom-header-blue-text"
                                          >{{formatMoneys(emi.paid_amount)}}</label
                                        >

                                      </td>
                                    </tr>
                                 
                                    <tr>
                                      <td style="vertical-align: middle">
                                        <label
                                          style="font-weight: bold; font-size: 18px"
                                          class="btx-label custom-header-blue-text"
                                          >{{formatMoneys(loandata.loan_amount)}}</label
                                        >
                                      </td>
                                      <td style="vertical-align: middle">
                                        <label
                                          style="font-weight: bold; font-size: 18px"
                                          class="btx-label custom-header-blue-text"
                                          >{{formatMoneys(loandata.loan_amount)}}</label
                                        >
                                      </td>
                                      <td><label
                                          style="font-weight: bold; font-size: 18px"
                                          class="btx-label custom-header-blue-text"
                                          >{{formatMoneys(getTotal())}}</label
                                        ></td>
                                     
                                      
                                    </tr>
                                  </template>
                                  <template v-else>
                  <tr>
                    <td colspan="9">
                      <p class="m-0 text-center">
                        <img
                          width="30"
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image mr-4"
                        />
                        Uhh... There are no Loan at the moment.
                      </p>
                    </td>
                  </tr>
                </template>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </table>
                        
              </v-col>
            </v-row>
              </v-layout>
            </template>
          </v-container>
        </template>
        <template v-slot:action>
         
          <v-btn
            class="ml-2"
            :disabled="excelLoading"
            depressed
            tile
            v-on:click="$emit('close', false)"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
    </v-sheet>
  </template>
  <script>
  import Dialog from "@/view/components/Dialog";
  //import FileUpload from "@/view/components/FileUpload";
  import { formatMoney } from "accounting-js";
  import { CREATE_LOAN, GET_PAYMENT,UPDATE_LOAN } from "@/core/lib/pos.lib";
  import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
//   import QuantityInput from "@/view/components/QuantityInput";
  import {isEmpty } from "lodash";
  import PosMixin from "@/core/mixins/pos.mixin";
  
//   import BookingDate from "@/view/components/BookingDate";
  // import TextInput from "@/view/components/TextInput";
//   import TextAreaInput from "@/view/components/TextAreaInput";
  // import PurchaseDocument from "@/view/components/PurchaseDocument";
  export default {
    name: "item-dialog",
    title: "Dialog Item",
    mixins: [PosMixin],
  
  
    data() {
      return {
        className: null,
        excelLoading: null,
        activeRowIndex:null,
        payment:0,
        payments:0,
  
        recordPaymentDialog: false,
        paymentThroughList: [
          { text: "Petty Cash", value: 1 },
          { text: "Undeposited Founds", value: 2 },
        ],
        paymentModeList: [
          { text: "Cash", value: 1 },
          { text: "Credit Card Citi Bank", value: 2 },
          { text: "Credit Card C I M B", value: 3 },
          { text: "Credit Card Public Bank", value: 4 },
          { text: "Boss", value: 4 },
        ],
        paymentRecordData: {
          payment_made: null,
          payment_number: null,
          date: null,
          payment_notes: null,
          loan_amount:null,
          total_emi:null,
          loan_id:null,
        
        },
        schedule:[],
        paymentRecord: [
          {
            po_number: "PO0001",
            paymentDate: "25/10/2023",
            venderName: "business thrust techsoft pvt ltd",
            amount: 309.88,
          },
        ],
      };
    },
    mounted() {
      this.getPayment();
      // this.calculateInstallment();
    },
    methods: {
        closeloan(){
            this.recordPaymentDialog=false;

        },
        getTotal(){
          this.product = this.loandata?.loan_rel

    		let sum = 0;
        console.log(this.pdetails)
    		this.product?.forEach((element) => {

    			sum = sum + Number(element.paid_amount);
    		});
        console.log(sum)
    		this.discount_total = Number(sum);
    		//  this.getDiscount();
    		return this.discount_total;

        },
      clickOutside() {
        console.log({ activeRowIndex: this.activeRowIndex });
        this.activeRowIndex = null;
      },
      checkPrice(row,rowindex){
  console.log(row,'row')
  let sum=0;
  row?.forEach((element) => {
          sum = sum + Number(element.payment);
        });
        this.payment = Number(sum);
        console.log(this.payment,'this.payment')
  
  if(this.payment > this.paymentRecordData.loan_amount)
  {
    this.schedule[rowindex].payment =null; 
    this.checkPrice(row,rowindex)
  
  }
  
      },
      onFocus(event, rowIndex) {
        this.activeRowIndex = rowIndex;
  
        this.$nextTick(() => {
          const ourForm = event.target;
          const ourInp = ourForm.querySelector("input");
          if (ourInp) ourInp.focus() || ourInp.select();
        });
  
        this.isOutside = false;
        const element = event.target;
        //console.log(element.nodeName);
        const _line_items = this.schedule.map((row) => {
          return { ...row, isEditable: false };
        });
        this.schedule = _line_items;
        this.schedule[rowIndex].isEditable = true;
        this.$nextTick(() => {
          const inputarea = element.querySelector("input");
          if (inputarea) {
            inputarea.focus();
            inputarea.select();
          }
        });
        if (element.nodeName == "INPUT") {
          element.select();
        }
        
      },
      getMonthName(date) {
        return new Date(date).toLocaleString("default", { month: "long" }); // Get the full month name
      },
      getYear(date) {
        return new Date(date).getFullYear(); // Get the year
      },
      calculateAmortization() {
        console.log(this.paymentRecordData.date,'this.paymentRecordData.date')
    const P = this.paymentRecordData.loan_amount; // Loan principal
    const n = this.paymentRecordData.total_emi; // Number of months
    
    const monthlyPayment = P / n; // Simple equal principal repayment
  
    let balance = P; // Initial balance
    this.schedule = []; // Reset the schedule
    let currentDate = new Date(this.paymentRecordData.date); // Initial date
    
    for (let i = 0; i < n; i++) {
      balance -= monthlyPayment; // Deduct the monthly payment from the balance
  
      const monthName = `${this.getMonthName(currentDate)}`; // Get the month name and year
      
      this.schedule.push({
        monthName,
        payment:0.00,
        balance: Math.max(0, balance), // Ensure no negative balance
      });
  
      currentDate.setMonth(currentDate.getMonth() + 1); // Increment the month by 1
    }
    
  },
   
    
      manageLimit(e) {
        if (
          this.paymentRecordData.payment_notes &&
          this.paymentRecordData.payment_notes.length > 249
        ) {
          e.preventDefault();
        }
      },
      onPaste(e) {
        let coppied = e.clipboardData.getData("Text");
        let preVal = this.paymentRecordData.payment_notes;
        let finalval = String(preVal) + String(coppied);
        if (Number(finalval.length) > 249) {
          let trimValue = finalval.substring(0, 250);
          this.paymentRecordData.payment_notes = trimValue;
          e.preventDefault();
        }
      },
      async getPayment() {
        const data = await GET_PAYMENT();
        this.paymentModeList1 = data;
        const paymentModeList2 = this.paymentModeList1.map((item) => {
          return {
            text: item.payment_type,
            value: item.value,
          };
        });
        this.paymentModeList = paymentModeList2;
      },
      formatMoney(money) {
        return formatMoney(money, {
          symbol: "RM",
          decimal: ".",
          thousand: ",",
          precision: 2,
          format: "%s%v",
        });
      },
      async saveRecord() {
        if (this.paymentRecordData && !this.paymentRecordData?.loan_amount) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The Amount field is required" },
          ]);
          return false;
        }
        if (this.paymentRecordData && !this.paymentRecordData?.total_emi) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The Instalment Number field is required" },
          ]);
          return false;
        }
        if (this.paymentRecordData && !this.paymentRecordData?.date) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The date field is required" },
          ]);
          return false;
        }
        this.pageLoading = true;
        this.paymentRecordData.employee_id= this.$route.params.id
        this.paymentRecordData.schedule= this.schedule
  
        try {
          if (this.paymentRecordData.loan_id) {
          
            const customer = await UPDATE_LOAN(this.paymentRecordData);
            console.log(customer);
  
            this.$router.replace({
              name: "employee-detail",
              params: { id: customer.id,},
              query: { t: new Date().getTime() },
            });
            this.$store.commit(SET_MESSAGE, [
              { model: true, message: "Loan Updated Successfully." },
            ]);
            this.recordPaymentDialog=false;
  
            this.$emit("success", "true");
  
          }else {
          const customer = await CREATE_LOAN(this.paymentRecordData);
          this.$router.replace({
            name: "employee-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Loan  Created Successfully." },
          ]);
          this.recordPaymentDialog=false;
        } 
        this.$emit("success", "true");
      }
        catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      
      },
      getAmount() {
        if (this.pdetails.total) {
          this.records = [this.pdetails];
          const record1 = this.records.map((item) => {
            return {
              barcode: item.barcode,
              total: item.total,
              display_name: item.supplier?.display_name,
            };
          });
          this.record = record1;
        } else {
          this.records = this.bulkdata;
  
          const record1 = this.records.map((item) => {
            return {
              barcode: item.barcode,
              total: item.total,
              display_name: item.supplier?.display_name,
            };
          });
          this.record = record1;
        }
  
        this.paymentRecordData.payment_number = this.record.length;
      },
  
      getTotals() {
        this.product = this.schedule;
  
        let sum = 0;
  
        this.product?.forEach((element) => {
          sum = sum + Number(element.payment);
        });
  
        this.paymentRecordData.payment = Number(sum);
        this.paymentRecordData.payment_made = Number(sum);
  
        return this.paymentRecordData.payment;
      },
    },
    watch: {
      dialog(params) {
        this.recordPaymentDialog = params;
        if (!isEmpty(this.loandata)) {
          
          this.paymentRecordData.total_emi = this.loandata?.total_emi
          this.paymentRecordData.loan_amount = this.loandata?.loan_amount
          this.paymentRecordData.date = this.loandata?.date
          this.paymentRecordData.loan_id = this.loandata?.id
  
  
        }
  
      },
    },
    props: {
      dialog: {
        type: Boolean,
        required: true,
        default: false,
      },
      loandata: {
        type: Object,
        default: () => {
          return {};
        },
      },
      bulkdata: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
  
    components: {
      Dialog,
      //FileUpload,
      // TextInput,
    //   BookingDate,
    //   TextAreaInput,
      
      // PurchaseDocument,
    //   QuantityInput,
    },
  };
  </script>
  <style scoped>
  .table.table-sm td {
    padding: 0.6rem !important;
    vertical-align: middle;
  }
  .table.table-sm th {
    background-color: #ede8e8 !important;
  }
  </style>
  